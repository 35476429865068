import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import MenuPC from "./MenuPC"
import MenuMobile from "./MenuMobile"

import "../../../css/layouts/site.css"
import "../../../css/theme.css"

const requeteImageFond = graphql`
  {
    imageFond: allImagesCompilationYaml(
      filter: { code: { in: ["ngc1788CosmicBat"] } }
    ) {
      edges {
        node {
          fichier: chemin_relatif {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                quality: 90
              )
            }
          }
        }
      }
    }
  }
`

export default function LayoutAccueil(props) {
  let image = useStaticQuery(requeteImageFond)
  return (
    <div id="conteneur-principal">
      <GatsbyImage
        id="body-conteneur"
        className="body-conteneur-image-fond-accueil"
        image={
          image.imageFond.edges[0].node.fichier.childImageSharp.gatsbyImageData
        }
        alt="m82"
      />
      {props.children}
      <div id="menu-pc-accueil-conteneur">
        <div id="menu-pc-espace-avant-menu"></div>
        <MenuPC page="index" />
      </div>
      <main>
        <MenuMobile />
      </main>
    </div>
  )
}

LayoutAccueil.propTypes = {
  children: PropTypes.object,
}
