import React from "react"

import LayoutAccueil from "../composants/layout/fr/LayoutAccueil"
import SEO from "../composants/layout/SEO"

export default function Home() {
  return (
    <LayoutAccueil>
      <SEO />
    </LayoutAccueil>
  )
}
