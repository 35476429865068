module.exports = {
  locales: {
    fr: {
      boutique: {
        acheter: "Acheter",
        acheterUnTirage: "Acheter un tirage photo",
        acheterUnTirageDescriptionPage:
          "Acheter un tirage photo d’astronomie, un cadeau idéal pour les amoureux de l’espace, des étoiles et de l’Univers.",
        ajouterAuPanier: "Acheter un tirage",
        aPartirDe: "À partir de",
        besoinDAide:
          "D’autres dimensions ? Une prise de contact avant l’achat ?",
        dimensions: "Dimensions",
        finition: "Finition",
        informationsLivraison: "Livraison en France métropolitaine incluse.",
        options: {
          0: "Tirage seul",
          1: "Contre-collage sur alu Dibond",
          2: "Cadre caisse américaine noire",
          3: "Cadre caisse américaine chêne",
        },
        pageContact: "contact",
        paiementSecurise: "Paiement sécurisé.",
        prix: "{0} €",
        prixTirage:
          "À partir de <strong>{0} €</strong> et jusqu’à <strong>{1} €</strong> selon options.",
        retourBoutique: "Retour à la boutique",
        selectionnezUneDimension: "Sélectionnez une dimension",
        selectionnezUneFinition: "Sélectionnez une finition",
        suffixeUrlImagePourTirage: "/tirage",
        tirage: "Tirage",
      },
      site: {
        accueil: "Home",
        acheterUnTirageLightbox:
          "Tirage photo disponible : à partir de <strong>{0}&nbsp;€</strong> et jusqu’à <strong>{1}&nbsp;€</strong> selon options.",
        astrophotographie: "astrophotographie",
        contact: "Envoyez-moi un message !",
        ouvrirFermerMenu: "Ouvrir et fermer le menu",
        fermer: "Fermer",
        partager: "Partager",
        partagerSurFacebook: "Partager sur Facebook",
        partagerSurTwitter: "Partager sur Twitter",
        plusDInformations: "Astrophotographie avec un réflex",
        pourAllerPlusLoin: "Pour aller plus loin…",
        retour: "Retour",
        retourGalerie: "Retour aux galeries",
        retourTutoriels: "Retour tutoriels",
      },
    },
    en: {
      boutique: {
        acheter: "Buy",
        acheterUnTirage: "Buy a photo print",
        acheterUnTirageDescriptionPage:
          "Buy an astronomical photo print, an ideal gift for lovers of the stars, the space and the Universe. ",
        ajouterAuPanier: "Add to cart",
        aPartirDe: "From",
        besoinDAide: "A question? A contact before the purchase?",
        dimensions: "Dimensions",
        finition: "Finish",
        informationsLivraison: "Shipping costs included.",
        options: {
          0: "Print only",
          1: "Laminating on aluminum Dibond",
          2: "Black shadow box",
          3: "Oak shadow box",
        },
        pageContact: "contactEN",
        paiementSecurise: "Secured payment.",
        prix: "€ {0}",
        prixTirage:
          "From <strong>€&nbsp;{0}</strong> to <strong>€&nbsp;{1}</strong> depending on options.",
        retourBoutique: "Back to the shop",
        selectionnezUneDimension: "Select a dimension",
        selectionnezUneFinition: "Select a finish",
        suffixeUrlImagePourTirage: "/print",
        tirage: "Print",
      },
      site: {
        accueil: "Home",
        acheterUnTirageLightbox:
          "Photo print available : from <strong>€&nbsp;{0}</strong> to <strong>€&nbsp;{1}</strong> depending on options.",
        astrophotographie: "astrophotography",
        contact: "Send me a message!",
        ouvrirFermerMenu: "Open and close the menu",
        fermer: "Close",
        partager: "Share",
        partagerSurFacebook: "Share on Facebook",
        partagerSurTwitter: "Share on Twitter",
        plusDInformations: "More informations",
        pourAllerPlusLoin: "Going further…",
        retour: "Back",
        retourGalerie: "Back to the gallery",
        retourTutoriels: "Back to tutorials",
      },
    },
  },
}
